<style lang="scss" scoped>
.coupons-form {
    background: #fff;
    padding: 0 15px;

    &::v-deep {

        .el-form-item--small {
            &.el-form-item {
                margin-bottom: 15px;

                &.margin-bottom-no {
                    margin-bottom: 0;
                }
            }

            .el-form-item__label {
                //line-height: normal;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        .el-checkbox__label {
            padding-left: 5px;
        }
    }
}
</style>

<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont coupons-form" v-loading="!loading">
                <basic-info-form
                    ref="basic-info-form-ref"
                    :dataForm="dataForm"
                    :type="type"
                    :disabled="showEdit"
                    @update:couponType="handleCouponType"
                    v-if="loading">
                </basic-info-form>
                <preferential-info-form
                    ref="preferential-info-form-ref"
                    :dataForm="dataForm"
                    :type="type"
                    :disabled="showEdit"
                    v-if="loading">
                </preferential-info-form>
                <scope-application-form
                    ref="scope-application-form-ref"
                    :dataForm="dataForm"
                    :type="type"
                    :disabled="showEdit"
                    v-if="loading">
                </scope-application-form>
                <create-editor-box
                    :form="createEditorInfo">
                </create-editor-box>
            </div>
        </el-main>

        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont align-r">
                <el-radio-group
                    v-if="type === '1' || type === '2'"
                    v-model="showEdit"
                    size="small">
                    <el-radio :label="true">查看模式</el-radio>
                    <el-radio :label="false" v-if="type === '1'">编辑模式</el-radio>
                </el-radio-group>
                <el-button
                    style="width: 80px;margin-left: 15px"
                    v-if="type === '1'"
                    type="primary"
                    @click="handleSubmit(1)"
                    size="small">
                    保存
                </el-button>
                <el-popover
                    v-if="type === '0'"
                    placement="top"
                    width="160"
                    v-model="visible">
                    <p style="padding: 10px 0">请确认添加优惠券信息</p>
                    <div style="text-align: right; margin: 0">
                        <el-button
                            style="margin-right: 15px"
                            type="text"
                            @click="visible = false"
                            size="mini">
                            取消
                        </el-button>
                        <el-button
                            type="primary"
                            :loading="submitLoading"
                            v-debounce="debounceSubmit"
                            size="mini">
                            确定
                        </el-button>
                    </div>
                    <el-button
                        style="width: 80px"
                        slot="reference"
                        type="primary"
                        size="small">
                        提交
                    </el-button>
                </el-popover>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import {couponsTypeItem, couponsStatusItem, couponsValidityItem} from '../../data'

import basicInfoForm from './components/basic-info-form'
import preferentialInfoForm from './components/preferential-info-form'
import scopeApplicationForm from './components/scope-application-form'
import createEditorBox from '../../components/create-editor-box'
import {addCoupon, updateCoupon, couponDetail} from '@/www/urls/promotionUrls'

export default {
    data() {
        return {
            typeItem: couponsTypeItem,
            statusItem: couponsStatusItem,
            validityItem: couponsValidityItem,
            basicInfoForm: {}, // 基本信息
            form: {},
            dataForm: {},
            showEdit: false, // 模式
            loading: false,
            visible: false,
            submitLoading: false,
        }
    },
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    components: {
        basicInfoForm,
        preferentialInfoForm,
        scopeApplicationForm,
        createEditorBox
    },
    //计算属性
    computed: {
        createEditorInfo() {
            let {
                createUserName,
                createTime,
                updateUserName,
                updateTime
            } = this.dataForm
            let form = {
                createName: createUserName,
                createTime,
                updateName: updateUserName,
                updateTime
            }
            return form
        },
    },
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        async init(){
            switch (this.type) {
                case '0':
                    this.loading = true
                    break;
                case '1':
                    await this.getCouponDetail()
                    this.showEdit = false;
                    break;
                case '2':
                    await this.getCouponDetail()
                    this.showEdit = true;
                    break;
            }
        },
        handleCouponType(type){
            this.$refs['preferential-info-form-ref'].handleCouponType(type)
        },
        // 优惠券详情
        async getCouponDetail() {
            let id = this.$route.params.id
            const res = await couponDetail(id)
            if (res.success && res.data) {
                this.dataForm = res.data
                this.loading = true
            }
        },
        // 提交
        debounceSubmit(){
            this.handleSubmit(0)
        },
        async handleSubmit(type) {
            try {
                let basicInfo = this.$refs['basic-info-form-ref'].submit()
                let preferentialInfo = this.$refs['preferential-info-form-ref'].submit()
                let scopeApplication = this.$refs['scope-application-form-ref'].submit()
                let promiseAll = await Promise.all([basicInfo, preferentialInfo, scopeApplication])
                if (promiseAll) {
                    this.submitLoading = true
                    let formObj = {}
                    promiseAll.forEach(item => {
                        formObj = {...formObj, ...item}
                    })
                    let res = {}
                    if(type === 0) res = await addCoupon(formObj)
                    if(type === 1) {
                        let {
                            id
                        } = this.dataForm
                        formObj.id = id
                        res = await updateCoupon(formObj)
                    }
                    if (res.success && res.data) {
                        // this.handleReset()
                        this.$message({
                            message: `${type === 0 ? '提交':'保存'}成功`,
                            type: 'success'
                        });
                        if(type === 0) this.$store.commit('delete_tabs', '/addCoupon');
                        if(type === 1) this.$store.commit('delete_tabs', `/editCoupon/${this.$route.params.id}`);
                        await this.$router.push('/couponList')
                    }
                    this.submitLoading = false
                }
            } catch (e) {
                console.log(e);
            }
        },
        // 重置
        handleReset() {
            this.$refs['basic-info-form-ref'].reset()
            this.$refs['preferential-info-form-ref'].reset()
            this.$refs['scope-application-form-ref'].reset()
        }
    },
    watch: {},
}
</script>
