<style lang="scss" scoped>
.all-check-box {
    display: flex;
    align-items: center;

    &::v-deep {
        .el-checkbox-group {
            line-height: inherit;
        }

        .el-checkbox-group {
            font-size: inherit;
        }

        .el-checkbox {
            margin-right: 10px;
        }

        .el-checkbox__label {
            padding-left: 0;
        }
    }
}
</style>


<template>
    <div class="all-check-box">
        <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="changeAll"
            :disabled="disabled">
            全部
        </el-checkbox>
        <el-checkbox-group
            v-model="checkSelect"
            @change="changeSelect"
            :disabled="disabled">
            <el-checkbox
                v-for="item in list"
                :label="item.value"
                :key="item.value">
                {{ item.label }}
            </el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    data() {
        return {
            checkAll: false,  // 全部
            isIndeterminate: false,
            checkSelect: []
        }
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        dataModel: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // type  选中数组拼  0101  type为1 回传为0101
        type: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: 0
        },
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            if(this.dataModel){
                this.changeCheckedList(this.dataModel)
            }
        },
        changeAll(val) {
            this.checkSelect = val ? this.list : [];
            this.isIndeterminate = false;
            let list = []
            if (val) {
                this.list.forEach((item) => {
                    list.push(item.value);
                    this.checkSelect = list
                })
            }
            this.$emit("input", list);
            this.$emit("update:checked", list, this.index);
        },
        changeSelect(val) {
            let list = JSON.parse(JSON.stringify(val))
            let length = list.length
            this.checkAll = length === this.list.length
            this.isIndeterminate = length > 0 && length < this.list.length
            this.$emit("input", list);
            this.$emit("update:checked", list, this.index);
        },
        changeCheckedList(arr) {
            this.checkSelect = arr
            this.changeSelect(this.checkSelect)
        },
        reset() {
            this.checkAll = false  // 全部
            this.isIndeterminate = false
            this.checkSelect = []
        }
    },
    watch: {},
}
</script>
