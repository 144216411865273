<style lang="scss" scoped>
.scope-application-form {
    .box-flex {
        display: flex;
    }
}
</style>


<template>
    <div class="scope-application-form">
        <section-box title="适用范围">
            <el-form :model="form" :rules="rules" ref="form" label-position="right" label-width="100px" size="small">
                <el-form-item label="适用城市:" prop="couponUseCityInfo">
                    <div class="box-flex">
                        <el-checkbox v-model="form.cityChecked" @change="changeChecked('cityChecked')"
                                     :disabled="disabled">全部
                        </el-checkbox>
                        <el-select
                            class="ml10"
                            v-model="form.couponUseCityInfo"
                            multiple
                            filterable
                            :disabled="form.cityChecked || disabled"
                            placeholder="请选择服务城市">
                            <el-option
                                v-for="item in cityList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.shortCode">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="业务类型:" prop="couponUseProductType">
                    <all-check-box
                        :dataModel="form.couponUseProductType"
                        :list="businessTypesItem"
                        @update:checked="handleChecked"
                        v-model="form.couponUseProductType"
                        ref="all-check-box-ref"
                        :disabled="disabled">
                    </all-check-box>
                </el-form-item>
                <el-form-item label="适用机构:" prop="couponUseOrgInfo">
                    <div class="box-flex">
                        <el-checkbox v-model="form.orgChecked" @change="changeChecked('orgChecked')"
                                     :disabled="disabled">全部
                        </el-checkbox>
                        <el-select
                            class="ml10"
                            v-model="form.operatorId"
                            filterable
                            :disabled="form.orgChecked || disabled"
                            placeholder="请选择运营商"
                            @change="changeOperator">
                            <el-option
                                v-for="item in operatorList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select
                            class="ml10"
                            v-model="form.supplierId"
                            filterable
                            :disabled="form.orgChecked || disabled"
                            placeholder="请选择供应商">
                            <el-option
                                v-for="item in supplierList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
            </el-form>
        </section-box>
    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../../../../components/section-box'
import {couponsBusinessTypes} from '@/data'
import {getCityData, getOperatorList, getOperaIdSupplierList} from '@/urls';
import allCheckBox from '../../../../components/all-check-box'

const allCity = [{cityId: '999', cityName: '全部城市'}]
const initForm = {
    couponUseCityInfo: [],
    cityChecked: false, // 城市
    couponUseProductType: [],
    orgChecked: false, // 机构全部
    operatorId: '',
    supplierId: '',
}
export default {
    data() {
        return {
            form: {
                ...initForm
            },
            cityList: [], // 城市列表
            orgList: [], // 机构列表
            operatorList: [],
            supplierList: [],
            businessTypesItem: couponsBusinessTypes,
            rules: {
                couponUseCityInfo: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.cityChecked) callback()
                            if (value && value.length > 0) {
                                callback()
                            } else callback(new Error('请选择适用城市'));
                        },
                        trigger: 'change'
                    }
                ],
                couponUseProductType: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback()
                            } else callback(new Error('请至少选择一个业务类型'));
                        },
                        trigger: 'change'
                    }
                ],
                couponUseOrgInfo: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.orgChecked) callback()
                            if (this.form.operatorId || this.form.supplierId) {
                                callback()
                            } else callback(new Error('请选择适用机构'));
                        },
                        trigger: 'change'
                    }
                ],
            }
        }
    },
    props: {
        dataForm: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    components: {
        sectionBox,
        allCheckBox
    },
    //计算属性
    computed: {},
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getCityList();
            this.getOperator();
            if (this.dataForm && this.type !== '0') {
                let {
                        couponUseCityInfo,
                        couponUseProductType,
                        operatorId,
                        supplierId
                    } = this.dataForm,
                    dataForm = {};
                // 城市
                let dataCityList = JSON.parse(couponUseCityInfo)
                if (dataCityList[0].cityName === '全部城市') {
                    dataForm.cityChecked = true
                } else {
                    dataForm.cityChecked = false
                    dataForm.couponUseCityInfo = dataCityList.map(item => {
                        return item.cityId
                    })
                }
                // 业务类型
                if (couponUseProductType) {
                    let dataTypeList = JSON.parse(couponUseProductType)
                    dataForm.couponUseProductType = dataTypeList
                    this.$refs['all-check-box-ref'].changeCheckedList(dataTypeList)
                }

                // 机构
                if (operatorId) {
                    dataForm.operatorId = operatorId
                    this.getSupplier(operatorId)
                    if(supplierId) dataForm.supplierId = supplierId
                    dataForm.orgChecked = false
                } else dataForm.orgChecked = true

                this.form = dataForm
            }

        },
        // 获取城市
        async getCityList() {
            const res = await getCityData(this, '2');
            if (res) this.cityList = res;
        },
        // 运营商列表
        async getOperator() {
            const res = await getOperatorList()
            if (res) this.operatorList = res
        },
        // 供应商列表
        async getSupplier(id) {
            const res = await getOperaIdSupplierList(id)
            if (res) this.supplierList = res
        },
        // 机构列表
        // async getOrgList() {
        //     const res = await getOrganizationList();
        //     if (res) {
        //         this.orgList = res
        //     }
        // },
        // 运营商选中
        changeOperator(id){
            this.getSupplier(id)
        },
        changeChecked(type) {
            switch (type) {
                case 'cityChecked':
                    if (this.form.cityChecked) this.form.couponUseCityInfo = []
                    break;
                case 'orgChecked':
                    if (this.form.orgChecked) {
                        this.form.operatorId = ''
                        this.form.supplierId = ''
                    }
                    break;
            }
        },
        handleChecked() {
            this.$refs['form'].validateField('couponUseProductType')
        },
        submit() {
            return new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let {
                                couponUseCityInfo,
                                cityChecked, // 城市
                                couponUseProductType,
                                orgChecked, // 机构全部
                                operatorId,
                                supplierId
                            } = this.form,
                            form = {};
                        form.couponUseProductType = JSON.stringify(couponUseProductType)

                        if (cityChecked) form.couponUseCityInfo = JSON.stringify(allCity)
                        else {
                            let applyCityArr = couponUseCityInfo.map(item => {
                                let obj = {}
                                this.cityList.map(item1 => {
                                    if (item === item1.shortCode) {
                                        obj.cityId = item1.shortCode
                                        obj.cityName = item1.name
                                    }
                                })
                                return obj
                            })
                            form.couponUseCityInfo = JSON.stringify(applyCityArr)
                        }
                        if (!orgChecked) {
                            form.operatorId = operatorId
                            if(supplierId) form.supplierId = supplierId
                        }
                        resolve(form)
                    } else {
                        reject();
                        return false;
                    }
                })
            })
        },
        reset() {
            this.form = {
                ...initForm
            }
            this.$refs['all-check-box-ref'].reset()
            this.$refs['form'].resetFields()
        }
    },
    watch: {},
}
</script>
