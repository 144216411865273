<style lang="scss" scoped>
.create-editor-box {
    font-size: 14px;
    padding-bottom: 30px;

    li {
        display: flex;
        align-items: center;
        height: 32px;

        span {
            display: inline-block;
        }
    }

    .title-l {
        text-align: right;
        width: 120px;
        color: #606266;
    }

    .name {
        margin-right: 10px;
        position: relative;

        &:before {
            content: "";
            display: block;
            height: 12px;
            width: 1px;
            border-radius: 2px;
            background: #333;
            position: absolute;
            right: -6px;
            top: 50%;
            margin-top: -5px;
        }
    }
}
</style>


<template>
    <div class="create-editor-box" v-if="(form.createName && form.createTime) || (form.updateName && form.updateTime)">
        <section-box title="创建及编辑">
            <ul>
                <li v-if="form.createName && form.createTime">
                    <p class="title-l">
                        <span class="name">创建人</span>
                        <span>时间：</span>
                    </p>
                    <p>
                        <span class="name">{{ form.createName }}</span>
                        <span>{{ form.createTime | dateCus("yyyy-MM-dd hh:mm:ss") }}</span>
                    </p>
                </li>
                <li v-if="form.updateName && form.updateTime">
                    <p class="title-l">
                        <span class="name">最后编辑</span>
                        <span>时间：</span>
                    </p>
                    <p>
                        <span class="name">{{ form.updateName }}</span>
                        <span>{{ form.updateTime | dateCus("yyyy-MM-dd hh:mm:ss") }}</span>
                    </p>
                </li>
            </ul>
        </section-box>

    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../section-box'

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        form: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        sectionBox
    },
}
</script>
