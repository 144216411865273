<style lang="scss" scoped>
.section-box{
    .title{
        position: relative;
        padding-left: 8px;
        font-size: 14px;
        font-weight: 600;
        height: 32px;
        line-height: 32px;

        &:before{
            content: "";
            display: block;
            height: 12px;
            width: 2px;
            border-radius: 2px;
            background: #319BF7;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -6px;
        }
    }

}
</style>


<template>
    <div class="section-box">
        <div class="title">
            {{title}}
        </div>
        <div class="section">
            <slot></slot>
        </div>

    </div>

</template>

<script type="text/ecmascript-6">

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: ''
        }

    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
