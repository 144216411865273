<style lang="scss" scoped>
.preferential-info-form {
    &::v-deep {

        .el-form-item--small {
            &.item-label-line {
                .el-form-item__label {
                    line-height: 16px;
                }
            }

        }
    }
}
</style>


<template>
    <div class="preferential-info-form">
        <section-box title="优惠信息">
            <el-form :model="form" :rules="rules" ref="form" label-position="right" label-width="100px" size="small">
                <el-form-item label="减免金额:">
                    <ul>
                        <li>
                            <el-button
                                v-for="item in derateTypeItem"
                                :key="item.value"
                                :type="form.couponDerateType === item.value ? 'primary': ''"
                                @click="handleDerateType(item)"
                                :disabled="disabled">
                                {{ item.label }}
                            </el-button>
                        </li>
                        <li class="mt15" v-if="form.couponDerateType === 0">
                            <el-form-item prop="couponPrice" class="margin-bottom-no">
                                <el-input v-model="form.couponPrice" placeholder="请输入固定金额"
                                          style="width: 300px"
                                          :disabled="disabled"></el-input>
                                元
                            </el-form-item>
                        </li>
                        <li class="mt15" v-if="form.couponDerateType === 1">
                            金额在
                            <el-select
                                style="width: 70px"
                                v-model="form.couponRandomMinPrice"
                                filterable
                                @change="changeRandom('min')"
                                placeholder="最小随机金额"
                                :disabled="disabled">
                                <el-option
                                    v-for="item in minPriceItem"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                            至
                            <el-select
                                style="width: 70px"
                                v-model="form.couponRandomMaxPrice"
                                filterable
                                placeholder="最大随机金额"
                                :disabled="disabled">
                                <el-option
                                    v-for="item in maxPriceItem"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                            元范围内的整数值
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item label="最低满减额:">
                    <el-select
                        style="width: 70px"
                        v-model="form.couponMinUsePrice"
                        filterable
                        :disabled="form.thresholdChecked || disabled"
                        placeholder="最低满减额">
                        <el-option
                            v-for="item in fullReductionItem"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                    元
                    <el-checkbox
                        v-model="form.thresholdChecked"
                        @change="changeThresholdChecked"
                        :disabled="disabled">
                        无门槛
                    </el-checkbox>
                </el-form-item>
                <!--                <el-form-item label="发券数量:">-->
                <!--                    <el-input v-model="form.orderNo" placeholder="不限量"></el-input>-->
                <!--                    元-->
                <!--                    <el-checkbox v-model="form.checked">不限量</el-checkbox>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="订单取消优惠券是否返还:" class="item-label-line">-->
                <!--                    <el-radio-group v-model="radio">-->
                <!--                        <el-radio :label="3">是</el-radio>-->
                <!--                        <el-radio :label="6">否</el-radio>-->
                <!--                    </el-radio-group>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="是否支持叠加使用:" class="item-label-line">-->
                <!--                    <el-radio-group v-model="radio">-->
                <!--                        <el-radio :label="3">是</el-radio>-->
                <!--                        <el-radio :label="6">否</el-radio>-->
                <!--                    </el-radio-group>-->
                <!--                </el-form-item>-->
            </el-form>
        </section-box>
        <!--        <el-button @click="submit">提交</el-button>-->
    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../../../../components/section-box'
import {couponsDerateTypeItem} from '../../../../data'

const initForm = {
    couponType: 0,
    couponDerateType: 0, // 减免金额
    couponPrice: '', // 优惠金额  固定金额
    couponRandomMinPrice: 1, // 最小随机金额
    couponRandomMaxPrice: 2, // 最大随机金额
    couponMinUsePrice: 1, // 最低满减额
    thresholdChecked: false, // 最低满减额 无门槛选项
}
export default {
    name: '',
    data() {
        return {
            form: {
                ...initForm
            },
            rules: {
                couponPrice: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error('请填写金额'))
                            } else if (isNaN(value)) {
                                callback(new Error('输入的金额格式不正确'));
                            } else
                                callback();
                        },
                        trigger: 'blur'
                    }
                ]
            },
        }
    },
    props: {
        dataForm: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    components: {
        sectionBox
    },
    //计算属性
    computed: {
        // 减免金额
        derateTypeItem() {
            let arr = []
            if (this.form.couponType === 0) arr = [{value: 0, label: "固定金额"}]
            if (this.form.couponType === 1) arr = couponsDerateTypeItem
            return arr
        },
        // 减免金额  最小随机
        minPriceItem() {
            let arr = []
            for (let i = 1; i <= 98; i++) {
                arr.push(i)
            }
            return arr
        },
        // 减免金额  最大随机
        maxPriceItem() {
            let arr = []
            for (let i = this.form.couponRandomMinPrice + 1; i <= 99; i++) {
                arr.push(i)
            }
            return arr
        },
        // 最低满减额
        fullReductionItem() {
            let arr = []
            for (let i = 1; i <= 99; i++) {
                arr.push(i)
            }
            return arr
        }
    },
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.dataForm && this.type !== '0') {
                let {
                        couponType,
                        couponDerateType, // 减免金额
                        couponPrice, // 优惠金额  固定金额
                        couponRandomMinPrice, // 最小随机金额
                        couponRandomMaxPrice, // 最大随机金额
                        couponMinUsePrice, // 最低满减额
                    } = this.dataForm,
                    dataForm = {
                        couponType,
                        couponDerateType, // 减免金额
                        couponMinUsePrice, // 最低满减额
                    };
                if (couponDerateType === 0) dataForm.couponPrice = couponPrice
                if (couponDerateType === 1) {
                    dataForm.couponRandomMinPrice = couponRandomMinPrice
                    dataForm.couponRandomMaxPrice = couponRandomMaxPrice
                }
                if (couponMinUsePrice > 0) dataForm.thresholdChecked = false
                else dataForm.thresholdChecked = true
                this.form = dataForm
            }
        },
        handleCouponType(value){
            this.form.couponType = value
        },
        // 减免金额
        handleDerateType(item) {
            this.form.couponDerateType = item.value
        },
        // 减免金额  最小金额 最大金额
        changeRandom(type) {
            switch (type) {
                case 'min':
                    this.form.couponRandomMaxPrice = this.maxPriceItem[0]
                    break
            }
        },
        // 无门槛 选项
        changeThresholdChecked() {
            if (this.form.thresholdChecked) this.form.couponMinUsePrice = 0
            else this.form.couponMinUsePrice = 1
        },
        submit() {
            return new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let {
                                couponDerateType,
                                couponPrice, // 优惠金额  固定金额
                                couponRandomMinPrice, // 最小随机金额
                                couponRandomMaxPrice, // 最大随机金额
                                couponMinUsePrice, // 最低满减额
                            } = this.form,
                            form = {
                                couponDerateType,
                                couponMinUsePrice
                            };
                        if (couponDerateType === 0) form.couponPrice = couponPrice
                        if (couponDerateType === 1) {
                            form.couponRandomMinPrice = couponRandomMinPrice
                            form.couponRandomMaxPrice = couponRandomMaxPrice
                        }
                        resolve(form)
                    } else {
                        reject();
                        return false;
                    }
                })
            })
        },
        reset() {
            this.form = {
                ...initForm
            }
            this.$refs['form'].resetFields()
        }

    },
    watch: {},
}
</script>