<style lang="scss" scoped>
.basic-info-form {

}
</style>


<template>
    <div class="basic-info-form">
        <section-box title="基本信息">
            <el-form :model="form" :rules="rules" ref="form" label-position="right" label-width="100px" size="small">
                <el-form-item label="优惠券ID:" class="margin-bottom-no" v-if="form.couponNo">
                    {{ form.couponNo }}
                </el-form-item>
                <el-form-item label="优惠券状态:" class="margin-bottom-no">
                    {{ form.couponStatus | formatDesc(statusItem) }}
                </el-form-item>
                <el-form-item label="绑定状态:" class="margin-bottom-no">
                    {{ form.activitiesId ? '已绑定' : '未绑定' }}
                </el-form-item>
                <el-form-item label="优惠类型:" style="margin-bottom: 8px">
                    <!--                            <el-button v-for="item in typeItem" :key="item.value">{{ item.label }}</el-button>-->
                    满减
                </el-form-item>

                <el-form-item label="优惠券类型:">
                    <el-button
                        v-for="item in typeItem"
                        :key="item.value"
                        :type="form.couponType === item.value ? 'primary': ''"
                        @click="handleType(item)"
                        :disabled="disabled">
                        {{ item.label }}
                    </el-button>
                </el-form-item>
                <el-form-item label="优惠券名称:" prop="couponName">
                    <el-input
                        v-model="form.couponName"
                        placeholder="新人福利"
                        style="width: 300px"
                        :disabled="disabled">
                    </el-input>
                </el-form-item>
                <el-form-item label="有效期:">
                    <ul>
                        <li>
                            <el-button
                                v-for="item in validityItem"
                                :key="item.value"
                                :type="form.couponValidType === item.value ? 'primary': ''"
                                @click="handleValidity(item)"
                                :disabled="disabled">
                                {{ item.label }}
                            </el-button>
                        </li>
                        <li class="mt15"
                            v-if="form.couponValidType === 0 && form.activitiesStartTime && form.activitiesEndTime">
                            {{ form.activitiesName + '，' }}{{ form.activitiesStartTime | dateCus("yyyy-MM-dd hh:mm:ss") }}-{{ form.activitiesEndTime | dateCus("yyyy-MM-dd hh:mm:ss") }}
                        </li>
                        <li class="mt15" v-if="form.couponValidType === 1">
                            领券后
                            <el-input-number
                                v-model="form.couponValidDay"
                                @change="handleChange"
                                :min="1"
                                :max="90"
                                label="描述文字"
                                :disabled="disabled">
                            </el-input-number>
                            天内可用
                        </li>
                    </ul>
                </el-form-item>
            </el-form>
        </section-box>
    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../../../../components/section-box'
import {couponsStatusItem, couponsValidityItem, preferentialTypeItem} from '../../../../data'

const initForm = {
    couponNo: '',  // 优惠券ID
    couponStatus: 0, // 优惠券状态
    couponName: '', // 优惠券名称
    couponType: 0, // 优惠券类型
    couponValidType: 0, // 有效期
    couponValidDay: 1, // 有效期 固定天数 领券后
}
export default {
    data() {
        return {
            form: {
                ...initForm
            },
            rules: {
                couponName: [
                    {required: true, message: '请填写优惠券名称', trigger: 'blur'}
                ]
            },
            typeItem: preferentialTypeItem,
            statusItem: couponsStatusItem,
            validityItem: couponsValidityItem,
        }
    },
    props: {
        dataForm: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    components: {
        sectionBox
    },
    //计算属性
    computed: {},
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {

            if (this.dataForm && this.type !== '0') {
                let {
                        activitiesId, // 活动id
                        couponNo,  // 优惠券ID
                        couponStatus, // 优惠券状态
                        couponName, // 优惠券名称
                        couponType, // 优惠券类型
                        couponValidType, // 有效期
                        couponValidDay, // 有效期 固定天数 领券后
                        activitiesName,
                        activitiesStartTime,
                        activitiesEndTime,
                    } = this.dataForm,
                    dataForm = {
                        couponNo,
                        couponStatus,
                        couponName,
                        couponType,
                        couponValidType,
                        couponValidDay,
                        activitiesName,
                        activitiesStartTime,
                        activitiesEndTime,
                    };
                if(activitiesId) dataForm.activitiesId = activitiesId
                this.form = dataForm
            }
        },

        // 活动类型
        handleType(item) {
            this.form.couponType = item.value
            this.$emit('update:couponType', item.value)
        },
        // 有效期
        handleValidity(item) {
            this.form.couponValidType = item.value
            if (this.dataForm && this.type !== '0' && this.dataForm.couponValidDay) {
                this.form.couponValidDay = this.dataForm.couponValidDay
            } else this.form.couponValidDay = 1
        },

        handleChange(v) {
            console.log(v);
        },
        submit() {
            return new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let {
                                couponName,
                                couponType,
                                couponValidType,
                                couponValidDay
                            } = this.form,
                            form = {
                                couponName,
                                couponType,
                                couponValidType,
                            };
                        if (couponValidType === 1) form.couponValidDay = couponValidDay
                        resolve(form)
                    } else {
                        reject()
                        return false;
                    }
                });
            })
        },
        reset() {
            this.form = {
                ...initForm
            }
            this.$refs['form'].resetFields()
        }
    },
    watch: {},
}
</script>
